import axios from 'axios'

export default{
    name:"connexion",

    data(){
        return{
            email:null,
            mdp:null,
            estConnecte:false,

            emailValide:false
        }
    },

    methods:{
        connecter(){
           
                axios.get('http://localhost:3000/auth/test', {
 
                })
                     .then((result) => {
                          console.log(result)
                     });
           
            this.$store.dispatch('connecterUtilisateur', { identifiant: this.email});
            this.$router.push('/');
        },

        verifierEmail(){
            axios.post('http://localhost:3000/auth/login', {
                         email: 'coucou',
                         password: 'coucou',
                    });
            this.emailValide=true
        },

        resilierPassword(){

        }
    },

    created(){
        this.estConnecte = this.$store.getters.estAuthentifie;
        this.unwatch = this.$store.watch(
        (state, getters) => getters.estAuthentifie,
        (newValue) => {
            this.estConnecte = newValue;
        });
    }, 

    beforeUnmount() {
        this.unwatch();
      },
}