<template>
  <div
    v-if="!estConnecte"
    class="d-flex justify-content-center align-items-center"
  >
    <div style="font-size: 190%; flex-basis: 30%" class="border-gris-fonce p-5 bg-blanc-casse">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <span>Sign in</span>
        <input
          type="text"
          style="flex-basis: 75%"
          class="form-control form-control-lg mt-3"
          placeholder="Enter email or identifiant"
          v-model="email"
          autofocus
        />

        <input
        v-if="emailValide"
          type="password"
          style="flex-basis: 75%"
          class="form-control form-control-lg mt-3"
          placeholder="ex: azerty12"
          v-model="mdp"
        />
        <a href="" v-if="emailValide" @click.prevent.stop="resilierPassword()" class="text-left" style="font-size:50%">Forget the password ?</a>
        <div class="w-100">
          <button
            class="btn btn-info mt-3 w-100 text-center p-1 btn-modifie"
            @click="() => {emailValide ? connecter() : verifierEmail()}"
          >
            {{emailValide ? "Valider" : "Continue"}}
          </button>
        </div>
        <span style="font-size: 50%" class="mt-3">OR</span>
        <div>
          <button class="btn btn-light mt-3 w-100 text-center p-1 btn-modifie d-flex align-items-center justify-content-start" style="border:1px solid black">
            <img
              src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.340/static/media/google-logo.e086107b.svg"
              style="width: 8%"
              alt=""
              class="mr-4"
            />
            <span style="font-size: 80%" class="ml-5">Continue with Google</span>
          </button>
        </div>
        <hr/>
        <span>Sign up</span>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <p class="mt-5 h1">vous êtes déjà connecté</p>
    <button @click="$router.push('/')" class="btn btn-softy p-1">
      Redirection vers le menu
    </button>
  </div>
</template>

<script src="./connexion.js"></script>

<style scoped>
.btn-modifie {
  font-size: 2rem;
  border-radius: 10px;
}
</style>